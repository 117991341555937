module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.12.0_gatsby@5.12.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2_nh6625n6uvmgbvus3n6pg3ncva/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"alternis.io","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"../resources/logo2.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3d73cf1541795cd5308b1f8098f7169"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.12.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2.0_react@18.2.0__react@18.2.0__u3mwfx5syfmbsuglglqa5xukqi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
